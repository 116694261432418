import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { StationScreen } from "./routes/StationScreen";
import Login from "./components/Login";
import theme from "./theme";

// Disable debug logs based on env variables
if (process.env?.REACT_APP_DEBUG_MODE === "true") {
  console.debug(
    `Debug logging is enabled. Set the 'REACT_APP_DEBUG_MODE' env variable to 'false' (or delete it) to disable debug logs.`
  );
  console.debug(`Environment variables:`, process.env);
} else {
  console.debug = () => null;
}

const root = createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter basename="/live">
      <Routes>
        <Route path="/:instance?" element={<Login />} />
        <Route path=":group" element={<StationScreen />}>
          <Route path="/:group/:instance?" element={<StationScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);

reportWebVitals();
