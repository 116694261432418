import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const BreakfastWarningModal = ({ isOpen, onSubmit, onCancel }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="xs">
      <ModalOverlay background="rgba(255,255,255, 0.75)">
        <ModalContent background="andelGreen15" color="andelGreen75">
          <ModalBody h="100vh">
            <Flex
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              m="20px"
            >
              <Flex direction="column" justifyContent="center">
                <Text as="b" fontSize="2xl">
                  Det er snart tid til frokost.
                </Text>
                <br />
                <Text as="b" fontSize="2xl">
                  Ønsker I stadig en ny station?
                </Text>
              </Flex>{" "}
              <Flex
                direction="column"
                justifyContent="center"
                h="20vh"
                m="20px"
                w="100%"
              >
                <Button variant="primary" onClick={onSubmit} mt="10px" mb="10px" w="100%">
                  Ja tak
                </Button>
                <Button variant="cancel" onClick={onCancel} mt="10px" mb="10px" w="100%">
                  Nej tak
                </Button>
              </Flex>
              <Text as="b" fontSize="md" h="20vh">
                Efter frokost får I en helt ny station. I kommer
                 ikke tilbage hertil.{" "}
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
