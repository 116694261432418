import { Flex, Text } from "@chakra-ui/react";

export const StationDetails = ({ station, goToNextStation, systemStatus }) => {

  // TODO - figure out how to handle when there are no stations left
  const noAvailableStations = (
    <>
      <Text fontSize="lg" as="b" m="20px">
        No available stations at the moment
      </Text>
      <Text fontSize="lg" m="20px">
        Please wait a few minutes
      </Text>
    </>
  );

  const gameHasNotBeenStarted = (
        <>
            <Text fontSize="lg" as="b" m="20px">
                The game has not been started yet
            </Text>
            <Text fontSize="lg" m="20px">
                Please wait
            </Text>
        </>
  );

  // TODO - Figure out file upload
  const stationInfo = (
    <>
      <Text fontSize="5xl" as="b">
        {station?.name}
      </Text>
      <br />
      <Text fontSize="4xl" textAlign="center">
        {station?.typeName}
      </Text>
    </>
  );

  // TODO - figure out the "other groups" sections - need info on the API

  return (
      systemStatus !== 1 && systemStatus !== 2 ?
            <Flex
              direction="column"
              background="white"
              m="20px"
              p="20px"
              w="80%"
              alignItems="center"
            >
              {
                  systemStatus == 7 ?
                    noAvailableStations
               :
                      systemStatus == 0 ?
                            gameHasNotBeenStarted
                          :
                            <>
                              {goToNextStation === true ? (
                                <Text fontSize="4xl">Gå til station</Text>
                              ) : (
                                <Text fontSize="4xl">I er på station</Text>
                              )}
                              {stationInfo}
                            </>
              }
            </Flex>
          :
            <Flex
                  direction="column"
                  background="white"
                  m="10px"
                  p="0px"
                  w="0"
                  h="0"
                  alignItems="center"
              >
            </Flex>
  );
};
