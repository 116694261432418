import React, { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Box, Button, Input, FormControl, FormLabel } from "@chakra-ui/react";
import axios from "axios";

let { REACT_APP_BACKEND_API } = process.env;

const Login = () => {
    const { group, instance } = useParams();
    const [groupName, setGroupName] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (groupName.trim()) {
            try {
                const response = await axios.get(
                    `${REACT_APP_BACKEND_API}/api/fairground/check-group/${groupName}` + (instance ? `/${instance}` : ''),
                    {
                        responseType: "json",
                        cors: false,
                        transformResponse: (data) => {
                            return JSON.parse(data);
                        },
                    }
                );
                if (response.data.exists) {
                    navigate(`/${groupName}`);
                } else {
                    setError('Group does not exist');
                }
            } catch (error) {
                setError('An error occurred. Please try again.');
            }

        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <form onSubmit={handleSubmit}>
                <FormControl id="group-name" isRequired>
                    <FormLabel>Group Name</FormLabel>
                    <Input
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </FormControl>
                {error && <Box color="red.500" mt={2}>{error}</Box>}
                <Button mt={4} colorScheme="teal" type="submit">
                    Submit
                </Button>
            </form>
        </Box>
    );
};

export default Login;