import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";

export const SystemMessage = ({ systemStatus, stationTypeMessage }) => {

    let message = null;

    switch (systemStatus) {
        //Case 1: Game closed, lunch flag raised
        case 1:
            message = <div>Andel LIVE vender tilbage kl. 13.55</div>;
            break;
        //Case 2: Game closed, finish flag raised
        case 2:
            message = <div>Tak for denne gang</div>;
            break;
        //Case 5: Game open, lunch flag raised
        case 5:
            message = <div>Frokost begynder kl. 11.40</div>;
            break;
        //Case 6: Game open, finish flag raised
        case 6:
            message = <div>Nu er vi snart ved vejs ende</div>;
            break;

    }

    return (
        (message || stationTypeMessage) &&
            <Flex
                direction="column"
                background="white"
                m="0px"
                p="20px"
                w="80%"
                alignItems="center"
            >
            <InfoIcon color="andelPeach100" boxSize={8} m="10px" />
                <Text fontSize="md" as="b">
                    {message}
                </Text>
                <br/>

                <Text fontSize="md" textAlign="center">
                    <div dangerouslySetInnerHTML={{__html: stationTypeMessage}}/>
                </Text>
            </Flex>

    );
};
