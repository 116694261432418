export const Button = {
  defaultProps: {
    variant: "secondary", // Default button color scheme
  },
  variants: {
    primary: {
      color: "#ffffff",
      background: "andelGreen100",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "andelGreen75",
      },
      _active: {
        background: "andelGreen55",
      },
    },
    secondary: {
      color: "#ffffff",
      background: "andelGreen55",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "andelGreen35",
      },
      _active: {
        background: "andelGreen15",
      },
    },
    cancel: {
      color: "#ffffff",
      background: "andelPeach100",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "andelPeach75",
      },
      _active: {
        background: "andelPeach55",
      },
    },
  },
};
