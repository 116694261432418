// https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// For more information about the `theme` folder and its structure.
import { extendTheme } from "@chakra-ui/react";
import { Button } from "./components/Button";
import styles from "./styles";

const overrides = {
  // Foundational style overrides go here
  colors: {
    text: "#27374d",
    background: "#ffffff",
    accentBackground: "#2f2fa2",
    andelGreen100: "#003732",
    andelGreen75: "#406965",
    andelGreen55: "#73918e",
    andelGreen35: "#a6b9b7",
    andelGreen15: "#d9e1e0",
    andelGreen8: "#ebeded",
    andelPeach100: "#ff9e64",
    andelPeach75: "#ffb68b",
    andelPeach55: "#ffcaaa",
    andelPeach35: "#ffddc9",
    andelPeach15: "#fff0e8",
    andelPeach8: "#fff7f3",
    backgroundGray: "#fafafa",
    backgroundRed: "#f9d4d4",
    textRed: "#e22828",
  },
  styles,
  components: {
    Button,
  },
};

export default extendTheme(overrides);
